.concept {
    .page-mv {
        background-image: url(../images/concept/mv.jpg);
        background-size: cover;
        background-position: center;
        height: 800px;
        position: relative;
        @include view-at(sp) {
            height: 400px;
        }
        span.en {
            position: absolute;
            bottom: 20px;
            left: 40px;
            font-size: 50px;
            font-family: relation-one, sans-serif;
            font-weight: 400;
            color:white;
            @include view-at(sp) {
                font-size: 30px;
                left: 20px;
            }
        }
    }
}

body {
  font-family: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: black;
  letter-spacing: 0.05em;
  &.active {
    height: 100%;
    overflow: hidden;
  }
}

a {
  color: black;
  text-decoration: none;
  transition: all 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

p {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

ul,ol {
  list-style: none;
}


.page-mv {
  height: 400px;
  background-color: $color-gray-snow;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include view-at(sp) {
    height: 300px;
  }

  &::before {
    content: "";
    display: block;
    height: 100px;
    width: 1px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include view-at(sp) {
      height: 70px;
    }
  }
  h2 {
    text-align: center;
    margin-top: 40px;
    @include view-at(tab) {
      margin-top: 0;
    }
  }
}
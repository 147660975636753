/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 40px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-black;
  background-color: $color-white;
  position: relative;
  font-size: 14px;
  font-family: $serif;
  font-weight: bold;
  border: 1px solid black;
  line-height: 1em;
  @include view-at(sp) {
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
    text-decoration: none;
    color: white;
    background-color: black;
  }

  &.black {
    background: black;
    color: white;
    &:hover {
      color: black;
      background-color: white;
    }
  }

  &--arrow {
    width: 100%;
    max-width: 400px;
    text-align: left;
    padding: 20px 30px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 30px;
      height: 1px;
      background: #333;
      transition: all .3s;
    }
    &::after {
      content: '';
      position: absolute;
      top: 45%;
      right: 18px;
      width: 12px;
      height: 1px;
      background: #333;
      transform: rotate(35deg);
      transition: all .3s;
      @include view-at(sp) {
        top: 43%;
      }
    }
    &:hover {
      &::before, &::after {
        background: white;
      }
    }

    &-left {
      &::before {
        right: auto;
        left: 20px;
      }
      &::after {
        right: auto;
        left: 20px;
        top: 44%;
        transform: rotate(-35deg);
      }
    }

    &--wide {
      max-width: 500px;
    }

    &.orange {
      background-color: #FF731D;
      color: white;
      border: none;
      &::before, &::after {
        background: white;
      }
      &:hover {
        background-color: #000;
      }
    }
  }

  &--primary {
    background-color: #FF731D;
    border: 0;
    color: $color-white;
    line-height: 1em;

    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
    &:hover {
      background-color: #000;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  &--large {
    padding: 20px;
    font-size: nth($font-size, 3);
    min-width: 200px;
  }

  &--small {
    padding: 10px;
    font-size: nth($list: $font-size, $n: 5)
  }

  &--link {
    font-size: 14px;
    color: #009940;
    text-decoration: underline;
    @include view-at(sp) {
      font-size: 12px;
    }
    &::before {
      content: "";
      width: 7px;
      height: 7px;
      display: inline-block;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      margin-right: 10px;
    }
  }

  &--blank {
    color: #009940;
    text-decoration: underline;
  }

  &--uncer {
    background: #009940;
    color: white;
    padding: 15px;
    width: 100%;
    max-width: 400px;
    border: none;
    text-align: center;
    @include view-at(sp) {
      font-size: 12px;
    }
    // &::before {
    //   content: "";
    //   width: 7px;
    //   height: 7px;
    //   display: inline-block;
    //   border-top: 1px solid;
    //   border-right: 1px solid;
    //   transform: rotate(45deg);
    //   margin-right: 10px;
    // }
  }
}

/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-gray-snow;
  padding: 20px;
  &--narrow {
    padding: 15px;
  }

  &--border {
    border: 1px solid $color-gray-light;
    background-color: $color-white;
  }

  &--accent {
    background: $color-green-light;
    font-weight: bold;
  }

  &-contents {
    padding: 20px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }
}


.kaiin {
    &-mv {
        height: 500px;
        background: url(../images/kaiin/mv.jpg) no-repeat center;
        background-size: cover;
        @include view-at(sp) {
            height: auto;
            padding: 80px 15px 150px;
        }
        &::before {
            content: none;
        }
        h2 {
            font-size: 60px;
            color: white;
            @include view-at(sp) {
                font-size: 45px;
                line-height: 1.2em;
            }
            small {
                background: white;
                display: inline-block;
                padding: 5px 30px;
                color: black;
                @include view-at(sp) {
                    margin-bottom: 10px;
                }
            }
        }
        h3 {
            color: white;
        }
        .btn {
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            width: 350px;
            @include view-at(sp) {
                position: unset;
                transform: none;
            }
        }
        .icon {
            position: absolute;
            bottom: -30px;
            left: 69%;
            width: 190px;
            @include view-at(sp) {
                width: 140px;
            }
        }
    }
}
.stepbar {
    display: flex;
    flex-wrap: wrap;
    @include view-at(sp) {
        display: block;
    }
    &wrap {
        margin: 2em 0;
        position: relative;
        width: 20%;
        @include view-at(sp) {
            width: 100%;
        }
        .steptitle {
            @include view-at(sp) {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
            }
            .stepcircle {
                display: block;
                width: 7em;
                height: 7em;
                content: "";
                border-radius: 50%;
                background: #009940;
                color: #fff;
                text-align: center;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                @include view-at(sp) {
                    width: 4em;
                    height: 4em;
                    line-height: 4.7em;
                    text-align: center;
                    margin: 0;
                }
                svg {
                    font-size: 2em;
                }
            }
            .title {
                margin: 0.5em;
                font-weight: bold;
                font-size: 1.0em;
                text-align: center;
                @include view-at(sp) {
                    margin: 0 10px;
                    text-align: left;
                }
                &-num {
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-family: $noto;
                    font-weight: bold;
                    color: #009940;
                    @include view-at(sp) {
                        margin: 0 10px;
                        text-align: left;
                    }
                }
            }
        }
        .stepline {
            width: calc(100% - 40px);
            height: 1px;
            background: #009940;
            position: absolute;
            top: 3.5em;
            left: 8.5em;
            @include view-at(sp) {
                width: 1px;
                height: calc(100% - 20px);
                top: 3.5em;
                left: 2em;
            }
        }
        &:last-of-type .stepline:last-of-type {
            display: none;
        }
    }
}

.merit {
    &-wrap {
        background: $color-gray-snow;
        position: relative;
        padding: 20px 10px;
        text-align: center;
        .num {
            font-weight: bold;
            font-family: $noto;
            font-size: 40px;
            margin-bottom: 20px;
            text-align: center;
            position: absolute;
            top: -13px;
            color: #009940;
        }
    }
}

.list {
    filter: drop-shadow(0px 0px 6px rgba(0,0,0,.3));
    &-wrap {
        padding: 10px;
        background: white;
        h3 {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 10px;
            font-weight: bold;
            font-family: $serif;
            img {
                max-width: 30px;
                max-height: 30px;
                margin-right: 10px;
            }
        }
    }
    &-btn {
        a {
            padding: 15px;
            width: 100%;
            &:nth-last-child(1) {
                background-color: #FF731D;
                border-color: #FF731D;
            }
        }
    }
}
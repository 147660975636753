/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  display: block;
  thead {
    tr {
      @include view-at(sp) {
        border-bottom: 0;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    td {
      font-weight: $bold;
      text-align: center;
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
      }
    }
  }

  tbody {
    display: block;
    tr {
      display: flex;
      flex-wrap: wrap;
      @include view-at(sp) {
        border-bottom: 0;
      }
      &.head {
        background-color: $color-gray-snow;
        @include view-at(sp) {
          display: none;
        }
        th,td {
          text-align: center;
          font-weight: bold;
        }
      }
    }
    th {
      padding: 15px;
      line-height: 1.5;
      font-weight: normal;
      width: 20%;
      background-color: $color-gray-snow;
      border-bottom: 5px solid white;
      border-right: 5px solid white;
      display: block;
      text-align: center;
      @include view-at(sp) {
        width: 100%;
        text-align: center;
        padding: 10px;
        min-height: auto;
        border-right: none;
      }
    }
    td {
      padding: 15px 20px;
      border-bottom: 5px solid white;
      width: 50%;
      display: block;
      background-color: $color-gray-snow;
      @include view-at(sp) {
        padding: 10px;
        width: 70%;
        background: none;
        font-size: 14px;
      }
      &:nth-last-child(1) {
        width: 30%;
        border-left: 5px solid white;
        display: flex;
        justify-content: center;
        @include view-at(sp) {
          padding: 10px 0;
          justify-content: flex-end;
        }
        span {
          display: inline-block;
          width: 27px;
          height: 27px;
          margin: 0 5px;
          @include view-at(sp) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &.sub {
    
    tbody {
      th {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        @include view-at(sp) {
          width: 100%;
        }
      }
      td {
        padding: 0;
        width: 80%;
        text-align: center;
        border-left: none;
        @include view-at(sp) {
          width: 100%;
        }
        > ul {
          width: 100%;
          display: block;
          > li {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 5px solid white;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            > div {
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              @include view-at(sp) {
                width: 100px;
                align-items: flex-start;
                padding: 15px 0;
              }
            }
            > ol {
              width: calc(100% - 200px);
              display: block;
              border-left: 5px solid white;
              @include view-at(sp) {
                width: calc(100% - 100px);
              }
              > li {
                padding: 15px 20px;
                border-bottom: 5px solid white;
                text-align: left;
                &:nth-last-child(1) {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
      tr {
        &.head {
          th,td {
            padding: 15px 20px;
          }
          th {
            width: 200px;
          }
          td {
            width: 200px;
            &:nth-last-child(1) {
              width: calc(100% - 400px);
              text-align: left;
              border-left: 5px solid white;
            }
          }
        }
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }
}

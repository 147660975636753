/* --------------------------
見出し
-------------------------- */



.c-h1 {
  font-size: nth($font-size, 1);
  font-family: $noto;
  font-weight: 900;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
  }

  small {
    display: block;
    font-size: 16px;
    font-family: $serif;
    line-height: 1.5em;
    margin-top: 10px;
    @include view-at(sp) {
      font-size: 14px;
    }
  }
  &.green {
    color: #003514;
  }
}

.c-h2 {
  text-align: center;
  margin: 0 auto 40px;
  font-family: $serif;
  font-weight: 900;
  font-size: nth($font-size, 2);
  line-height: 1.5;
  letter-spacing: 0.15em;
  text-indent: 0.2em;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
  }
}

.c-h3 {
  font-size: nth($font-size, 2);
  line-height: 1.5em;
  letter-spacing: 0.08em;
  font-family: $serif;
  font-weight: 900;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
  }
  &.border {
    padding-left: 15px;
    border-left: 5px solid #003514;
    line-height: 1.2em;
  }
}

.c-h4 {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.08em;
  font-weight: bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }

  &.border {
    padding-left: 15px;
    border-left: 5px solid #003514;
    line-height: 1.2em;
  }
}

.c-h5 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
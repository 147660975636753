.question {
    .page-mv {
        background-image: url(../images/question/mv.jpg);
        background-size: cover;
        background-position: center;
        height: 800px;
        position: relative;
        color: white;
        @include view-at(sp) {
            height: 400px;
        }
        h2 {
            margin-bottom: 40px;
            @include view-at(sp) {
                margin-bottom: 20px;
            }
        }
        h3 {
            margin-bottom: 0;
        }
    }

    .qa-list {
        dl {
            position: relative;
            margin: 30px 0 0;
            cursor: pointer;
            @include view-at(sp) {
                margin: 10px 0 0;
            }
            &:first-child {
                margin-top: 0;
            }
            &::after {
                position: absolute;
                top: 27px;
                right: 26px;
                display: block;
                width: 7px;
                height: 7px;
                margin: auto;
                content: '';
                transform: rotate(135deg);
                border-top: 2px solid #000;
                border-right: 2px solid #000;
                @include view-at(sp) {
                    top: 20px;
                    right: 20px;
                    width: 7px;
                    height: 7px;
                }
            }
            &.open::after {
                transform: rotate(-45deg);
            }

            dt {
                position: relative;
                margin: 0;
                padding: 20px 20px 20px 60px;
                font-weight: bold;
                background: $color-gray-snow;
                @include view-at(sp) {
                    padding: 16px 35px 16px 50px;
                    font-size: 14px;
                    line-height: 1.5em;
                }
                &::before {
                    font-size: 22px;
                    line-height: 1;
                    position: absolute;
                    top: 14px;
                    left: 20px;
                    display: block;
                    content: 'Q.';
                    color: $color-green;
                    font-family: $noto;
                    font-weight: bold;
                    @include view-at(sp) {
                        font-size: 16px;
                        top: 15px;
                        left: 20px;
                    }
                }
            }
            dd {
                position: relative;
                margin: 0;
                padding: 20px 20px 20px 60px;
                @include view-at(sp) {
                    margin: 0;
                    padding: 16px 16px 16px 50px;
                    font-size: 14px;
                }
                &::before {
                    font-size: 22px;
                    line-height: 1;
                    position: absolute;
                    left: 20px;
                    display: block;
                    content: 'A.';
                    font-weight: bold;
                    color: $color-green;
                    font-family: $noto;
                    @include view-at(sp) {
                        font-size: 16px;
                        left: 20px;
                        margin-top: 5px;
                    }
                }
                p {
                    margin: 30px 0 0;
                    line-height: 1.8em;
                    @include view-at(sp) {
                        margin: 30px 0 0;
                    }
                    &:first-child{
                        margin-top: 0;
                        @include view-at(sp) {
                            margin-top: 0;
                        }
                    }

                    span {
                        font-weight: bold;
                    }
                    a {
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
@charset "UTF-8";
.top {
    &-mv {
        position: relative;
        .slick-mv {
            margin-bottom: 0 !important;
            &-item {
                height: 100vh;
                background: url(../images/top/mv01.jpg);
                background-size: cover;
                background-position: bottom left;
                @include view-at(tab) {
                    height: 100vh;
                    background: url(../images/top/mv01-sp.jpg);
                    background-size: cover;
                    background-position: center;
                }
                @include view-at(sp) {
                    height: 90vh;
                }

                .text{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    text-align: center;
                    &-inner {
                        @include view-at(sp) {
                            padding: 0 10px;
                        }
                        &.white {
                            color: white;
                            filter: drop-shadow(0px 0px 5px black);
                        }
                        h2 {
                            font-size: 30px;
                            text-align: center;
                            margin-bottom: 20px;
                            font-family: $serif;
                            font-weight: bold;
                            line-height: 1.8em;
                            @include view-at(sp) {
                                font-size: 18px;
                                letter-spacing: 0;
                            }
                        }
                        p {
                            font-size: 16px;
                            line-height: 2em;
                            font-family: $serif;
                            font-weight: bold;
                            @include view-at(sp) {
                                font-size: 12px;
                            }
                        }
                        p.en {
                            font-size: 30px;
                            text-align: center;
                            font-family: relation-one, sans-serif;
                            font-weight: 400;
                            @include view-at(sp) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .slick-slide {
                &:nth-child(2) {
                    .slick-mv-item {
                        background: url(../images/top/mv02.jpg);
                        background-size: cover;
                        background-position: bottom left;
                        @include view-at(tab) {
                            background: url(../images/top/mv02-sp.jpg);
                            background-size: cover;
                            background-position: bottom;
                        }
                    }
                }
                &:nth-child(3) {
                    .slick-mv-item {
                        background: url(../images/top/mv03.jpg);
                        background-size: cover;
                        background-position: center;
                        @include view-at(tab) {
                            background: url(../images/top/mv03-sp.jpg);
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
                &:nth-child(4) {
                    .slick-mv-item {
                        background: url(../images/top/mv04.jpg);
                        background-size: cover;
                        background-position: center;
                        @include view-at(tab) {
                            background: url(../images/top/mv04-sp.jpg);
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
                &:nth-child(5) {
                    .slick-mv-item {
                        background: url(../images/top/mv05.jpg);
                        background-size: cover;
                        background-position: bottom left;
                        @include view-at(tab) {
                            background: url(../images/top/mv05-sp.jpg);
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
            }

            .slick-dots {
                top: 130px;
                right: 15px;
                width: auto;
                @include view-at(sp) {
                    top: 50px;
                    right: 5px;
                }
                li {
                    width: 10px;
                    height: 15px;
                    display: block;
                    button:before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: white;
                        opacity: 0.5;
                    }
                    &.slick-active {
                        button:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-news {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px;
            padding-right: 150px;
            background: rgba(255,255,255,.8);
            display: block;
            font-size: 14px;
            font-weight: bolder;
            @include view-at(sp) {
                width: 100%;
                font-size: 12px;
                padding: 15px 10px;
                position: relative;
                background: #F5F5F5;
            }
            
            tbody {
                @include view-at(sp) {
                    display: block;
                }  
            }
            tr {
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                }
            }
            th {
                border-right: 1px solid;
                padding-right: 20px;
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                    border-right: none;
                    border-bottom: 1px solid;
                    padding-right: 0;
                    padding-bottom: 10px;
                    text-align: center;
                }
            }
            td {
                padding-left: 20px;
                font-family: $serif;
                @include view-at(sp) {
                    display: block;
                    width: 100%;
                    padding-left: 0;
                    padding-top: 15px;
                    position: relative;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: #333;
                    transition: all .3s;
                    @include view-at(sp) {
                        top: 67%;
                        right: 10px;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 44%;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: #333;
                    transform: rotate(35deg);
                    transition: all .3s;
                    @include view-at(sp) {
                        top: 54%;
                        right: 8px;
                    }
                }
            }
        }

        &-cv {
            position: absolute;
            right: 10px;
            bottom: 10px;
            @include view-at(tab) {
                bottom: 0;
            }
            @include view-at(sp) {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            a.policy {
                padding: 15px 30px;
                background: white;
                display: block;
                font-family: $serif;
                font-size: 14px;
                font-weight: bolder;
                width: 300px;
                margin-bottom: 10px;
                position: relative;
                @include view-at(sp) {
                    font-size: 12px;
                    padding: 15px;
                    width: 250px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: #333;
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 43%;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: #333;
                    -webkit-transform: rotate(35deg);
                    transform: rotate(35deg);
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
            }
        }
    }

    a.special {
        background: linear-gradient(45deg, #838c93 0%, #5b6366 100%);
        padding: 30px;
        height: 250px;
        display: block;
        font-family: $serif;
        font-size: 14px;
        font-weight: bolder;
        width: 300px;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        pointer-events: none;
        @include view-at(sp) {
            font-size: 12px;
            padding: 15px;
            width: 100%;
            height: 150px;
        }
        strong {
            display: block;
            text-align: center;
            font-weight: 900;
            font-family: $noto;
            font-size: 22px;
            margin-bottom: 10px;
            @include view-at(sp) {
                font-size: 20px;
            }
        }
        span {
            opacity: 0.8;
        }
        // &::before {
        //     content: '';
        //     position: absolute;
        //     bottom: 20px;
        //     right: 20px;
        //     width: 30px;
        //     height: 1px;
        //     background: white;
        //     -webkit-transition: all .3s;
        //     transition: all .3s;
        // }
        // &::after {
        //     content: '';
        //     position: absolute;
        //     bottom: 23px;
        //     right: 18px;
        //     width: 12px;
        //     height: 1px;
        //     background: white;
        //     -webkit-transform: rotate(35deg);
        //     transform: rotate(35deg);
        //     -webkit-transition: all .3s;
        //     transition: all .3s;
        // }
    }

    &-list {
        &-map {
            @include view-at(sp) {
                .simplebar-scrollbar::before {
                    background-color: $color-green;
                }
                .simplebar-scrollbar.simplebar-visible::before {
                    background-color: $color-green;
                    opacity: 1;
                }
                .simplebar-track {
                    background-color: #ccc;
                    border-radius: 100px;
                }
                &-wrap {
                    width: 800px;
                    padding-bottom: 20px;
                }
            } 
        }
        #map {
            width: 100%;
            height: auto;
        }
        .swiper-container {
            width: 100%;
            margin: 40px 0;
            padding: 8% 0;
            @include view-at(sp) {
                margin-bottom: 0;
            }
            h3 {
                font-family: $serif;
                text-align: center;
                margin-top: 5px;
                font-weight: bold;
                font-size: 12px;
                @include view-at(sp) {
                    font-size: 15px;
                    margin-top: 10px;
                }
            }
            .u-text-cap {
                display: none;
                font-family: $serif;
                font-weight: bold;
            }
            a {
                &:hover {
                    opacity: 1;
                }
            }
        }
        
        .swiper-slide {
            transition: .7s;
            padding-bottom:20px;
        }
        
        .swiper-slide img {
            width: 100%;
        }
        
        .swiper-slide-active {
            z-index: 1;
            transform: scale(1.5);
            @include view-at(sp) {
                transform: scale(1.0);
            }
            .u-text-cap {
                display: block;
                background: rgba(0,0,0,.6);
                font-size: 8px;
                left: 0;
                bottom: 0;
                filter: none;
                padding: 5px 10px;
                line-height: 1.8em;
                width: 100%;
                text-align: center;
                @include view-at(sp) {
                    font-size: 12px;
                }
            }
        }
        .swiper-button-next, .swiper-button-prev {
            top: 45%;
            color: white;
        }
        .swiper-button-next:after, .swiper-button-prev:after {
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            border-top: solid 4px white;
            border-left: solid 4px white;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        .swiper-button-next:after {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        &-modal {
            h3 {
                font-size: 20px;
                font-family: $serif;
                font-weight: bold;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                @include view-at(sp) {
                    font-size: 18px;
                }
                img {
                    width: 50px;
                    padding-right: 10px;
                }
            }
            p {
                font-family: $serif;
                font-weight: 500;
            }
            &-icon {
                display: flex;
                justify-content: flex-end;
                @include view-at(sp) {
                    margin-bottom: 10px;
                }
                li {
                    padding: 0 5px;
                    width: 40px;
                }
            }
            &-btn {
                display: flex;
                flex-wrap: wrap;
                a {
                    display: block;
                    padding: 15px;
                    text-align: center;
                    margin: 0 5px;
                    width: calc(50% - 10px);
                    @include view-at(sp) {
                        margin: 5px 0 ;
                        width: 100%;
                    }
                }
            }
            &-cap {
                display: flex;
                justify-content: center;
                @include view-at(sp) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                &-wrap {
                    display: flex;
                    justify-content: center;
                    margin: 20px 0 0;
                    @include view-at(sp) {
                        margin: 10px 0 20px;
                    }
                    &-inner {
                        display: flex;
                        justify-content: center;
                        border: 1px solid #ababab;
                        padding: 10px 20px;
                        flex-wrap: wrap;
                    }
                    p {
                        font-family: $serif;
                        font-weight: bold;
                        margin: 0;
                        font-size: 12px;
                    }
                }
                li {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    padding: 0 7px;
                    font-family: $serif;
                    font-weight: bold;
                    @include view-at(sp) {
                        font-size: 10px;
                        padding: 5px 10px;
                    }
                    img {
                        width: 20px;
                        padding-right: 5px;
                        @include view-at(sp) {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    &-link {
        &-reservation {
            background: url(../images/top/reservation-bg.png) no-repeat;
            background-size: cover;
            background-position: top;
            color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 60px 80px;
            @media screen and (max-width: 1380px){
                padding: 40px;
                background-position: left;
            }
            @include view-at(sp) {
                display: block;
                padding: 60px 20px;
            }
            h2 {
                padding-right: 120px;
                filter: drop-shadow(0 0 4px black);
                @include view-at(laptop) {
                    padding-right: 40px;
                }
                @include view-at(sp) {
                    margin-bottom: 40px;
                }
            }
        }
        &-roundstyle {
            background: url(../images/top/roundstyle.png) no-repeat;
            background-size: cover;
            display: block;
            color: white;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            @include view-at(tab) {
                padding: 60px 20px;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 30px;
                height: 1px;
                background: white;
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 23px;
                right: 18px;
                width: 12px;
                height: 1px;
                background: white;
                -webkit-transform: rotate(35deg);
                transform: rotate(35deg);
                -webkit-transition: all .3s;
                transition: all .3s;
            }
            h2 {
                filter: drop-shadow(0 0 4px black);
            }
        }
        &-pro {
            img {
                width: 100%;
                max-width: none;
            }
        }
        &-question {
            display: block;
            padding: 40px;
            text-align: center;
            height: 250px;
            position: relative;
            background: url(../images/question/mv.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            color: white;
            font-weight: bold;
            color: white;
            font-weight: bold;
            filter: drop-shadow(0px 0px 5px gray);
            margin-bottom: 40px;
            @include view-at(sp) {
                padding: 60px 20px 80px;
                height: auto;
                background-size: 200%;
            }
            &::before {
                content: '';
                /* ↓暗いオーバーレイを半透明で配置 */
                background-color: rgba(0, 0, 0, 0.2);
                /* ↓全体を覆うように配置 */
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.5s;
            }
            h2,p {
                filter: drop-shadow(0px 0px 5px black) drop-shadow(0px 0px 5px black);
            }
            span {
                position: absolute;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.5s;
                @include view-at(sp) {
                    width: 90%;
                }
            }
            &:hover {
                opacity: 1;
                filter: none;
                &::before {
                    background-color: rgba(0, 0, 0, 0.6);
                }
                span {
                    background: black;
                    color: white;
                    &::before, &::after {
                        background: white;
                      }
                }
            }
        }
    }

    &-selection {
        .slick-selection {
            overflow: hidden;
            li {
                padding: 5px;
                h3 {
                    font-size: 14px;
                    text-align: center;
                    margin-top: 8px;
                    line-height: 1.5em;
                    color: #003514;
                    font-family: $serif;
                    font-weight: bold;
                }
            }
            .slick-next {
                right: 20px;
                top: 45%;
                @include view-at(sp) {
                    right: 35px;
                    top: 41%;
                }
                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    border-top: solid 4px white;
                    border-right: solid 4px white;
                    transform: rotate(45deg);
                }
            }
            .slick-prev {
                left: 20px;
                z-index: +1;
                top: 45%;
                @include view-at(sp) {
                    left: 35px;
                    top: 41%;
                }
                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    border-top: solid 4px white;
                    border-left: solid 4px white;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &-member {
        h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                padding: 0 20px;
            }
            &::before {
                content: "";
                display: block;
                height: 22px;
                width: 100px;
                background: url(../images/top/ttl.png) no-repeat;
                background-size: contain;
                background-position: center;
                @include view-at(sp) {
                    width: 70px;
                }
            }
            &::after {
                content: "";
                display: block;
                height: 22px;
                width: 100px;
                background: url(../images/top/ttl2.png) no-repeat;
                background-size: contain;
                background-position: center;
                @include view-at(sp) {
                    width: 70px;
                }
            }
        }
        .slick-member {
            overflow: hidden;
            li {
                padding: 5px;
                h4 {
                    margin-top: 8px;
                }
            }
            .slick-next {
                right: 20px;
                top: 40%;
                @include view-at(sp) {
                    right: 35px;
                    top: 41%;
                }
                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    border-top: solid 4px white;
                    border-right: solid 4px white;
                    transform: rotate(45deg);
                }
            }
            .slick-prev {
                left: 20px;
                z-index: +1;
                top: 40%;
                @include view-at(sp) {
                    left: 35px;
                    top: 41%;
                }
                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    border-top: solid 4px white;
                    border-left: solid 4px white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    
    #modalstyle {
        a {
            width: 100%;
            text-align: left;
        }
    }
}


// カレンダーカスタマイズ
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    text-align: center;
    border: 1px solid white !important;
    background: no-repeat !important;
}
.ui-widget.ui-widget-content {
    border: none !important;
}
.ui-widget-header {
    border: none !important;
    background: none !important;
}
/* 日曜日のカラー設定 */
td.ui-datepicker-week-end:last-child a.ui-state-default{
    color: #f00!important;       /* 文字色を設定 */
    &.ui-state-active {
        color: #fff !important;
    }
}
/* 土曜日のカラー設定 */
td.ui-datepicker-week-end a.ui-state-default{
    color: #00f!important;       /* 文字色を設定 */
    &.ui-state-active {
        color: #fff !important;
    }
}
/* ホバー時の動作 */
td.ui-datepicker-week-end a.ui-state-hover{
    opacity: 0.8;
}
/* 当日を示す色はそのまま */
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
    background-color: #fffa90!important;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background: black !important;
    color: #fff !important;
}
.ui-datepicker th {
    &:nth-last-child(1) {
        color: #f00 !important;
    }
    &:nth-last-child(2) {
        color: #00f !important;
    }
}

.p-gnav {
  @include view-at(tab) {
    display: none;
    background-color: $color-gray-snow;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: 80px;
  }

  &-list {
    display: flex;
    justify-content: center;

    @include view-at(tab) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
      padding: 15px;
    }

    &-item {
      display: flex;
      justify-content: center;

      @include view-at(tab) {
        padding: 10px;
        width: 100%;
        display: block;
      }

      @include view-at(sp) {
        padding: 5px;
        font-size: 12px;
      }
      
      > a,> span {
        font-size: nth($font-size, 5);
        letter-spacing: 0.05em;
        text-decoration: none;
        line-height: 1;
        font-weight: 600;
        padding: 25px 30px;
        cursor: pointer;
        @include view-at(laptop) {
          padding: 15px 20px;
          font-size: 14px; 
          width: 100%;
          text-align: left;
          display: block;
        }

        @include view-at(tab) {
          padding: 15px 20px;
          border: 1px solid;
        }
        &:hover {
          color: #fff;
          background: black;
          opacity: 1;
        }
      }

      &.current {
        > a {
          color: #fff;
          background: black;
        }
      }

      .inner {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.5s;
        position: absolute;
        top: 64px;
        left: 0;
        width: 100%;
        background: rgba(0,0,0,.8);
        @include view-at(laptop) {
          top: 58px;
        }
        @include view-at(tab) {
          position: unset;
          background: none;
          height: auto;
          opacity: 1;
          overflow: visible;
          display: none;
        }
        
        nav {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          color: white;
          @include view-at(tab) {
            position: relative;
            display: block;
            padding: 0 0 10px;
          }
          h3 {
            color: white;
            font-size: 14px;
            font-weight: 900;
            width: 200px;
            span {
              display: block;
              font-family: $noto;
              font-weight: 900;
              font-size: 22px;
              margin-bottom: 10px;
            }
            @include view-at(tab) {
              display: none;
            }
          }
          ol {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 200px);
            padding-left: 20px;
            margin: -10px;
            position: relative;
            @include view-at(tab) {
              width: 100%;
              padding-left: 0;
              margin: 0;
            }
            &::before {
              content: "";
              height: calc(100% - 20px);
              width: 1px;
              background: white;
              display: block;
              position: absolute;
              left: 0;
              top: 10px;
              @include view-at(tab) {
                content: none;
                color: black;
              }
            }
            li {
              width: calc(100% / 4);
              padding: 10px;
              @include view-at(tab) {
                width: calc(100% / 2);
              }
              @include view-at(sp) {
                width: calc(100% / 1);
              }
              a {
                color: white;
                padding: 0;
                font-family: $serif;
                font-size: 11px;
                @include view-at(tab) {
                  color: black;
                  font-weight: 900;
                  text-align: left;
                }
                span {
                  display: flex;
                  margin-top: 5px;
                  &::before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 4px solid white;
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    padding-right: 5px;
                    @include view-at(tab) {
                      border-left: 4px solid black;
                    }
                  }
                }
                img {
                  @include view-at(tab) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      @include view-at(pc) {
        &:hover {
          > .inner {
            height: auto;
            overflow: visible;
            opacity: 1;
          }
        }
      }
      &.sub {
        > span {
          @include view-at(tab) {
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              border-bottom: 2px solid;
              border-right: 2px solid;
              transform: rotate(45deg) translateY(-50%);
              position: absolute;
              top: 50%;
              right: 15px;
            }
          }
        }
      }
    }
    .sub2 {
      width: 100%;
      margin-top: 40px;
    }
  }
}

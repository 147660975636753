/* --------------------------
フォーム
-------------------------- */


form {
  width: 330px;
  filter: drop-shadow(0 0 4px black);
  @include view-at(laptop) {
      width: 250px;
  }
  @include view-at(sp) {
      width: 90%;
      margin: 0 auto;
  }
  p {
      font-weight: bold;
      font-size: 14px;
  }
  .form-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;
      p {
          padding: 10px;
          width: 50%;
      }
  }
  select, input[type="text"] {
      background-color: rgba(0,0,0,0.4);
      border: 1px solid white;
      padding: 8px 10px;
      border-radius: 0;
      color: white;
      min-width: 100px;
      border-radius: 0;
      outline: none;
      font-family: $sans;
      height: 35px !important;
      @include view-at(sp) {
          width: 100%;
      }
  }
  input.hasDatepicker {
      position: relative;
      cursor: pointer;
      background: url(../images/common/icon-calendar.png);
      background-color: rgba(0,0,0,0.4);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 95% center;
      width: 100%;
  }
  select {
      option {
          background: white;
          color: black;
          &:checked {
              background: black;
              color: white;
          }
      }
  }
  .form-price {
      select {
          width: 44%;
      }
  }
  #send {
      &.nolink {
          pointer-events: none;
          opacity: 0.6;
      }
  }
}


.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
  border-radius: unset;
}

.ms {
  &-choice {
    background: transparent !important;
    border-radius: unset !important;
    border-color: white !important;
    padding: 8px 10px !important;
    height: 35px !important;
    color: white !important;
    line-height: 35px !important;
    position: relative;
    font-family: $sans;
    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      top: 41%;
      right: 10px;
    }
    span {
      padding-left: 15px !important;
      &.placeholder {
        color: white !important;
      }
    }
  }
  &-drop {
    border-radius: unset !important;
    border: none !important;
    font-family: $sans;
    ul {
      padding: 5px 0;
      li {
        input[type="checkbox"] {
          margin-top:0;
        }
      }
    }
  }
  &-parent {
    width: 100% !important;
    background-color: rgba(0,0,0,0.4);
  }
}
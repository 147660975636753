.selection {
    .page-mv {
        
        &.sub {
            // background-image: url(../images/selection/01/mv.jpg);
            // background-size: cover;
            // background-position: center;
            height: 700px;
            position: relative;
            @include view-at(sp) {
                height: 400px;
            }
            h2 {
                position: absolute;
                top: 60px;
                left: -5px;
                text-align: left;
                color: white;
                @include view-at(sp) {
                    top: 32px;
                    left: -2px;
                }
                span {
                    font-size: 70px;
                    opacity: 0.6;
                    @include view-at(sp) {
                        font-size: 40px;
                    }
                }
                small {
                    font-size: 24px;
                    display: block;
                    padding-left: 40px;
                    filter: drop-shadow(0 0 6px black);
                    @include view-at(sp) {
                        font-size: 18px;
                        padding-left: 15px;
                    }
                }
            }
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            
            // &2 {
            //     background-image: url(../images/selection/02/mv.jpg);
            //     background-size: cover;
            //     background-position: center;
            //     @include view-at(sp) {
            //         background-image: url(../images/selection/02/mv-sp.jpg);
            //     }
            // }
            // &3 {
            //     background-image: url(../images/selection/03/mv.jpg);
            //     background-size: cover;
            //     background-position: center;
            //     @include view-at(sp) {
            //         background-image: url(../images/selection/03/mv-sp.jpg);
            //     }
            // }
            // &4 {
            //     background-image: url(../images/selection/04/mv.jpg);
            //     background-size: cover;
            //     background-position: center;
            //     @include view-at(sp) {
            //         background-image: url(../images/selection/04/mv-sp.jpg);
            //     }
            // }
            // &5 {
            //     background-image: url(../images/selection/05/mv.jpg);
            //     background-size: cover;
            //     background-position: center;
            //     @include view-at(sp) {
            //         background-image: url(../images/selection/05/mv-sp.jpg);
            //     }
            // }
        }
    }

    &-ttl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include view-at(sp) {
            display: block;
        }
        span {
            display: inline-block;
            width: 27px;
            height: 27px;
            margin-left: 5px;
            margin-bottom: 20px;
            @include view-at(sp) {
                width: 20px;
                height: 20px;
            }
        }
    }

    .thumbnail {
        margin-top: 5px;
        .slick-track {
            transform: unset !important;
            .slick-slide {
                padding: 2px;
            }
        }
        li {
            opacity: 0.3;
            transition: opacity .3s linear;
        }
        .slick-current {
            li{
                opacity: 1;
            }
        }
    }

    .slick-selection03 {
        overflow: hidden;
        li {
            padding: 5px;
            h3 {
                font-size: 14px;
                text-align: center;
                margin-top: 8px;
                line-height: 1.5em;
                color: #003514;
                font-family: $serif;
                font-weight: bold;
            }
        }
        .slick-next {
            right: 20px;
            top: 50%;
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                border-top: solid 4px white;
                border-right: solid 4px white;
                transform: rotate(45deg);
                filter: drop-shadow(0px 0px 4px black);
            }
        }
        .slick-prev {
            left: 20px;
            z-index: +1;
            top: 50%;
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                border-top: solid 4px white;
                border-left: solid 4px white;
                transform: rotate(-45deg);
                filter: drop-shadow(0px 0px 4px black);
            }
        }
    }

    &-osusume {
        background: $color-gray-snow;
        padding: 30px;
        padding-top: 60px;
        margin-top: 80px;
        position: relative;
        text-align: center;
        h3 {
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            background: white;
            padding: 15px 30px;
            font-size: 20px;
            font-weight: bold;
            border: 1px solid;
            width: 300px;
        }
        .num {
            font-weight: bold;
            font-family: $noto;
            font-size: 25px;
            margin-bottom: 20px;
            text-align: center;
        }
        .img {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
        p {
            font-weight: bold;
            font-size: 14px;
        }
    }

    &-list {
        li {
            a {
                display: block;
                background: gray;
                height: 300px;
                position: relative;
                @include view-at(sp) {
                    height: 200px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 30px;
                    height: 1px;
                    background: white;
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 23px;
                    right: 18px;
                    width: 12px;
                    height: 1px;
                    background: white;
                    -webkit-transform: rotate(35deg);
                    transform: rotate(35deg);
                    -webkit-transition: all .3s;
                    transition: all .3s;
                }
                h2 {
                    color: white;
                    position: absolute;
                    top: -5px;
                    left: 0;
                    span {
                        font-size: 50px;
                        opacity: 0.6;
                        line-height: 0;
                        @include view-at(sp) {
                            font-size: 30px;
                        }
                    }
                    small {
                        font-size: 20px;
                        padding-left: 40px;
                        filter: drop-shadow(0 0 4px black) drop-shadow(0 0 2px black);
                        @include view-at(sp) {
                            font-size: 18px;
                            padding-left: 15px;
                        }
                    }
                }
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
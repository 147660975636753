.modal{
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;

  &__bg{
    background: rgba(0,0,0,0.8);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__content{
    background: #fff;
    left: 50%;
    padding: 60px;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 1100px;
    width: 90%;

    @include view-at(sp) {
      width: calc(100%-30px);
      padding: 15px 15px 5px;
      height: calc(100% - 30px);
      overflow: hidden scroll;
		}
  }
  
  a.js-modal-close {
    @include view-at(sp) {
      display: block;
      text-align: center !important;
      margin-bottom: 20px;
    }
    &::before {
      content: "×";
      font-size: 50px;
      position: absolute;
      top: 0;
      right: 0;
      @include view-at(sp) {
        content: "CLOSE";
        font-size: 14px;
        position: unset;
        display: inline-block;
        font-family: $noto;
        font-weight: bold;
        padding: 10px 40px;
        background: black;
        color: white;
      }
    }
  }
}
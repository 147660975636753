.pro {
    overflow: hidden;
    .page-mv {
        background-image: url(../images/pro/mv.jpg);
        background-size: cover;
        background-position: center;
        height: 800px;
        position: relative;
        @include view-at(sp) {
            height: 400px;
            background-image: url(../images/pro/mv-sp.jpg);
        }
    }

    &-box {
        padding: 40px;
        background: -moz-linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        background: -webkit-linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        background: linear-gradient(45deg, #ecedea, #f8f8f6 50%, #ecedea);
        height: 100%;
        h3 {
            font-family: relation-one, sans-serif;
            font-weight: 400;
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            line-height: 2em;
        }
    }

    &-info {
        li {
            &.text {
                background: url(../images/pro/bg2.png) no-repeat;
                background-size: cover;
                color: white;
                padding: 40px;
                position: relative;
                @include view-at(sp) {
                    padding: 40px 15px 250px;
                }
                h2 {
                    font-size: 50px;
                    margin-bottom: 40px;
                    line-height: 1;
                    @include view-at(sp) {
                        font-size: 35px;
                    }
                    small {
                        padding-left: 30px;
                        margin-top: 20px;
                        @include view-at(sp) {
                            padding-left: 15px;
                        }
                    }
                }
                h3 {
                    font-family: $noto;
                    font-weight: bold;
                    padding-left: 30px;
                    @include view-at(sp) {
                        padding-left: 0;
                    }
                }
                table {
                    max-width: 600px;
                    position: relative;
                    z-index: +1;
                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 10px;
                        line-height: 1.5em;
                        font-size: 14px;
                        font-family: $serif;
                        font-weight: bold;
                        @include view-at(sp) {
                            font-size: 12px;
                        }
                        th {
                            width: 180px;
                            padding-left: 60px;
                            @include view-at(sp) {
                                width: 100px;
                                padding-left: 0;
                            }
                        }
                        td {
                            width: calc(100% - 180px);
                            @include view-at(sp) {
                                width: calc(100% - 100px);
                            }
                        }
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 67%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: url(../images/pro/pro.png) no-repeat;
                    background-size: auto 100%;
                    background-position: bottom right;
                    @include view-at(sp) {
                        width: 80%;
                        background-size: 100%;
                    }
                }
            }
            &.img {
                background: url(../images/pro/pro.jpg) no-repeat;
                background-size: cover;
                background-position: center;
                position: relative;
                @include view-at(tab) {
                    height: 500px;
                }
                @include view-at(sp) {
                    height: 400px;
                }
                h2 {
                    position: absolute;
                    bottom: -5px;
                    right: 0;
                    font-size: 50px;
                    line-height: 1;
                    color: white;
                    opacity: 0.5;
                    @include view-at(sp) {
                        font-size: 30px;
                        bottom: -5px;
                    }
                }
            }
        }
        &2 {
            li {
                &.text {
                    background: url(../images/pro/bg.png) no-repeat;
                    background-size: cover;
                    &::after {
                        bottom: 0;
                        right: -220px;
                        background: url(../images/pro/pro2.png) no-repeat;
                        background-size: auto 85%;
                        background-position: bottom right;
                        @include view-at(sp) {
                            width: 100%;
                            background-size: 100%;
                            right: -20%;
                        }
                    }
                }
                &.img {
                    background: url(../images/pro/pro2.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }

        &3 {
            li {
                &.text {
                    background: url(../images/pro/bg3.png) no-repeat;
                    background-size: cover;
                    &::after {
                        height: 72%;
                        bottom: 0;
                        right: 0;
                        background: url(../images/pro/pro3.png) no-repeat;
                        background-size: auto 100%;
                        background-position: 95% bottom;
                        @include view-at(sp) {
                            width: 100%;
                            background-size: 45%;
                            background-position: 50% bottom;
                        }
                    }
                }
                &.img {
                    background: url(../images/pro/pro3.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}